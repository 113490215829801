'use strict';

var $ = require('jquery');

module.exports = {
    backmini: function () {
        $('body').on('click', '.mini-cart-close', function() {
            $('.mini-cart-close-btn').trigger('click');
        });
    }
};
